import { createWebHistory, createRouter } from 'vue-router';

import Login from '@/components/Login.vue';
import Register from '@/components/Register.vue';
import Menu from '@/components/Menu.vue';

const routes = [
  {
    path: '/',
    name: 'menu',
    component: Menu,
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import('@/components/Menu.vue'),
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/components/Profile.vue'),
  },
  {
    path: '/items',
    name: 'items',
    component: () => import('@/components/ItemList'),
  },
  {
    path: '/items/add',
    name: 'items-add',
    component: () => import('@/components/ItemAddOrEdit'),
  },
  {
    path: '/items/:id',
    name: 'items-edit',
    component: () => import('@/components/ItemAddOrEdit'),
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('@/components/Cart'),
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('@/components/Checkout'),
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/components/OrderTable'),
  },
  {
    path: '/orders/:id',
    name: 'orders-edit',
    component: () => import('@/components/EditOrder'),
  },
  {
    path: '/my-order-list',
    name: 'MyOrderList',
    component: () => import('@/components/MyOrderList'),
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/components/UserList'),
  },
  {
    path: '/users/:id',
    name: 'users-edit',
    component: () => import('@/components/EditUser'),
  },
  {
    path: '/announcements',
    name: 'announcements',
    component: () => import('@/components/AnnouncementList'),
  },
  {
    path: '/announcements/add',
    name: 'announcements-add',
    component: () => import('@/components/AnnouncementAddOrEdit'),
  },
  {
    path: '/announcements/:id',
    name: 'announcements-edit',
    component: () => import('@/components/AnnouncementAddOrEdit'),
  },
  {
    path: '/recipes',
    name: 'RecipeList',
    component: () => import('@/components/RecipeList'),
  },
  {
    path: '/recipes/:id',
    name: 'RecipeEdit',
    component: () => import('@/components/RecipeEdit'),
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('@/components/CategoryList'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
