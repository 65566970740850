const _ = require('lodash')
const { USER_LOCAL_STORAGE_KEY } = require('@/helper/constants')

// Returns null if localStorage.getItem(USER_LOCAL_STORAGE_KEY) is null (i.e. USER_LOCAL_STORAGE_KEY not exists in localStorage)
exports.getUser = () => JSON.parse(localStorage.getItem(USER_LOCAL_STORAGE_KEY)) 

exports.handleHttpError = (e, _this) => {
  const msg = _.get(e, 'response.data.errors[0].message') || _.get(e, 'response.data')

  showPersistentErrorMessage(msg, _this)
  console.log(msg)
}

const showPersistentErrorMessage = (message, _this) => {
  _this.$message.error({ message, duration: 5000, showClose: true })
}