import http from '@/http-common'

const BASE_API_URL = '/itemFeedback'

class ItemFeedbackService {
  bulkCreate(data) {
    return http.post(`${BASE_API_URL}/bulkCreate`, data);
  }

  getMostRecentItemFeedbacks(itemId) {
    return http.get(`${BASE_API_URL}/getMostRecentItemFeedbacks/${itemId}`);
  }
}

export default new ItemFeedbackService();
