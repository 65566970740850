<template>
  <el-row justify="center"> 
    <el-card class="box-card" header="Login">
      <Form as="el-form" @submit="handleLogin" :validation-schema="schema">
        <InputWithValidation label="Email" name="email" />
        <InputWithValidation label="Password" name="password" type="password" />

        <el-button type="primary" native-type="submit" :loading="loading">Login</el-button>
      </Form>
      <el-alert v-if="message" :title="message" type="error" />
    </el-card>
  </el-row>
</template>

<script>
import { Form } from "vee-validate";
import * as yup from "yup";
import InputWithValidation from "./InputWithValidation.vue";

export default {
  name: "Login",
  components: {
    Form,
    InputWithValidation
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().required("Email is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push({ name: 'Menu' });
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push({ name: 'Menu' })
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
.box-card {
  width: 480px;
}
</style>