<template>
  <div id="app">
    <el-menu mode="horizontal" router>
      <!-- <el-menu-item v-if="!currentUser" route="/register" index="1">
        Sign Up
      </el-menu-item> -->

      <el-menu-item v-if="!currentUser" route="/login" index="2">
        Login
      </el-menu-item>

      <el-menu-item v-if="currentUser" route="/" index="4"> Menu </el-menu-item>

      <el-menu-item v-if="currentUser" route="/cart" index="5">
        <el-badge :value="totalQuantityInCart">Cart</el-badge>
      </el-menu-item>

      <el-menu-item v-if="currentUser" route="/my-order-list" index="6">
        My Orders
      </el-menu-item>

      <el-menu-item v-if="currentUser" route="/announcements" index="3">
        News
      </el-menu-item>

      <el-menu-item v-if="currentUser" route="/profile" index="7">
        My Account
      </el-menu-item>

      <el-menu-item v-if="isAdmin" route="/items" index="8">
        Items
      </el-menu-item>

      <el-menu-item v-if="isAdmin" route="/categories" index="9">
        Categories
      </el-menu-item>

      <el-menu-item v-if="isAdmin" route="/orders" index="10">
        Orders
      </el-menu-item>

      <el-menu-item v-if="isAdmin" route="/users" index="11">
        Users
      </el-menu-item>

      <el-menu-item v-if="isAdmin" route="/recipes" index="12">
        Recipes
      </el-menu-item>
    </el-menu>

    <router-view></router-view>

    <el-dialog v-model="showDialog" title="News">
      <div v-for="announcement in announcements" :key="announcement.id">
        <h3>
          {{ announcement.title }} -
          {{ new Date(announcement.createdAt).toLocaleDateString() }}
        </h3>
        <div v-html="announcement.content"></div>
        <el-divider></el-divider>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UserService from "./services/UserService";
import AnnouncementService from "./services/AnnouncementService";

export default {
  name: "App",
  data() {
    return {
      announcements: [],
      showDialog: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      if (this.currentUser && this.currentUser["roles"]) {
        return this.currentUser["roles"].includes("ROLE_ADMIN");
      }

      return false;
    },
    totalQuantityInCart() {
      return this.$store.state.cart.orderItems.reduce(
        (total, oi) => total + oi.quantity,
        0
      );
    },
  },
  methods: {},
  async created() {
    try {
      const response = await AnnouncementService.getAllUnread();
      this.announcements = response.data;
      console.log("announcements: ", response.data);

      if (this.announcements.length > 0) {
        this.showDialog = true;
      }

      await UserService.updateLastVisitAt();
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style>
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.report-btn {
  margin-top: 50px;
}
</style>
