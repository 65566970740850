import axios from 'axios';
import { getUser } from '@/helper/utils'

const axiosInstance = axios.create({
  baseURL:
    (process.env.NODE_ENV === 'production'
      ? process.env.VUE_APP_DOMAIN_PROD
      : 'http://localhost:8080') + '/api',
  headers: {
    'Content-type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(config => {
  const user = getUser()

  config.headers['x-access-token'] = user?.accessToken;

  return config;
});

export default axiosInstance;