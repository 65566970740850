import http from '../http-common'

const BASE_API_URL = '/menu'

class MenuService {
  findByDate(date) {
    return http.get(`${BASE_API_URL}?date=${date}`)
  }

  getMostRecentMenu() {
    return http.get(`${BASE_API_URL}/getMostRecentMenu`)
  }

  getClosestFutureMenu() {
    return http.get(`${BASE_API_URL}/getClosestFutureMenu`)
  }
}

export default new MenuService();
