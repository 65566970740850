import http from "../http-common";

class UserService {
  getAll() {
    return http.get("/users");
  }
  
  get(id) {
    return http.get(`/users/${id}`);
  }

  getMy() {
    return http.get("/users/my");
  }

  updateLastVisitAt() {
    return http.get("/users/visit");
  }
}

export default new UserService();