<template>
  <el-row justify="center"> 
    <el-card class="box-card" header="Sign Up">
      <Form as="el-form" v-if="!successful" @submit="handleRegister" :validation-schema="schema">
        <InputWithValidation label="Email" name="email" type="email" />
        <InputWithValidation label="Password" name="password" type="password" />
        <InputWithValidation label="First Name" name="firstName" />
        <InputWithValidation label="Last Name" name="lastName" />
        <InputWithValidation label="Phone" name="phone" />

        <el-button type="primary" native-type="submit" :loading="loading">Sign Up</el-button>
      </Form>
      <el-alert v-if="message" :title="message" :type="successful ? 'success' : 'error'" />
    </el-card>
  </el-row>
</template>

<script>
import { Form } from "vee-validate";
import * as yup from "yup";
import InputWithValidation from "./InputWithValidation.vue";

export default {
  name: "Register",
  components: {
    Form,
    InputWithValidation
  },
  data() {
    const schema = yup.object().shape({
      email: yup
        .string()
        .required("Email is required!")
        .email("Email is invalid!")
        .max(50, "Must be maximum 50 characters!"),
      password: yup
        .string()
        .required("Password is required!")
        .min(4, "Must be at least 4 characters!")
        .max(40, "Must be maximum 40 characters!"),
      firstName: yup
        .string()
        .required("First Name is required!"),
      lastName: yup
        .string()
        .required("Last Name is required!"),
      phone: yup
        .string()
        .required("Phone is required!")
    });

    return {
      successful: false,
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/profile");
    }
  },
  methods: {
    handleRegister(user) {
      this.message = "";
      this.successful = false;
      this.loading = true;

      this.$store.dispatch("auth/register", user).then(
        (data) => {
          this.message = data.message;
          this.successful = true;
          this.loading = false;
        },
        (error) => {
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.box-card {
  width: 480px;
}
</style>