import http from '../http-common';

class AnnouncementService {
  create(data) {
    return http.post('/announcements', data);
  }

  getAll() {
    return http.get('/announcements');
  }

  getAllUnread() {
    return http.get('/announcements/unread');
  }

  get(id) {
    return http.get(`/announcements/get/${id}`);
  }

  update(id, data) {
    return http.put(`/announcements/update/${id}`, data);
  }

  delete(id) {
    return http.delete(`/announcements/delete/${id}`);
  }
}

export default new AnnouncementService();
