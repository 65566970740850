const orderItems = JSON.parse(localStorage.getItem('orderItems')) || [];
const initialState = { orderItems }

export const cart = {
  namespaced: true,
  state: initialState,
  actions: {
    addToCart({ commit, state }, item) {
      for (const orderItem of state.orderItems) {
        if (orderItem.item.id === item.id ) {
          orderItem.quantity += 1
          commit('setOrderItems', state.orderItems)
          localStorage.setItem('orderItems', JSON.stringify(state.orderItems))
          return
        }
      }

      state.orderItems.push({
        item,
        quantity: 1
      })

      commit('setOrderItems', state.orderItems)
      localStorage.setItem('orderItems', JSON.stringify(state.orderItems))
    },
    deleteFromCart({ commit, state }, orderItem) {
      const newOrderItems = state.orderItems.filter(oi => oi.item.id !== orderItem.item.id)
      commit('setOrderItems', newOrderItems)
      localStorage.setItem('orderItems', JSON.stringify(newOrderItems))
    },
    emptyCart({ commit }) {
      commit('setOrderItems', [])
      localStorage.setItem('orderItems', JSON.stringify([]))
    }
  },
  mutations: {
    setOrderItems(state, orderItems) {
      state.orderItems = orderItems;
    },
  }
};