<template>
  <div>
    <el-date-picker 
      v-model="date" 
      type="date" 
      placeholder="Pick a day"
      :disabled="!isAdmin"
      @change="retrieveMenuByDate"
      value-format="YYYY-MM-DD"
      style="margin-top: 10px"
    />

    <h1>{{ displayMenuTitle() }}</h1>

    <el-button v-if="isAdmin" type="primary" @click="openDialog">
      아이템 추가
    </el-button>
    
    <div v-if="menu">
      <div v-for="category in categories" :key="category.id" :gutter="20">
        <h2>{{category.name}}</h2>

        <el-row :gutter="10">
          <el-col
            v-for="item in category.items"
            :key="item.id"
            :md="8"
          >
            <el-card class="item-card">
              <el-image
                v-if="item.imageUrl"
                :src="item.imageUrl"
                style="width: 150px; height: 150px"
              />
              <h4>{{ item.name }}</h4>

              <p>{{ item.description }}</p>

              <p>$ {{ item.price }}</p>

              <p v-if="item.limited">{{ item.stock > 0 ? `Only ${item.stock} remaining!` : 'Out of stock' }}</p>

              <el-button type="primary" @click="addToCart(item)">Add to cart</el-button>

              <div v-if="isAdmin">
                <el-button  type="danger" @click="removeItemFromMenu(item)">
                  Delete
                </el-button>
                <el-button @click="openItemFeedbackDialog(item)">
                  최근 피드백 보기
                </el-button>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
    
    <el-dialog
      v-model="dialogVisible"
      title="아이템 리스트"
      width="75%"
    >
      <el-form :inline="true" @submit.prevent="retrieveItems">
        <el-form-item>
          <el-input v-model="query" placeholder="아이템 이름으로 검색" clearable />
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="retrieveItems">
            <el-icon style="vertical-align: middle">
              <Search />
            </el-icon>
            <span style="vertical-align: middle">검색</span>
          </el-button>
        </el-form-item>
      </el-form>

      <el-table :data="items">
        <el-table-column prop="category.name" label="카테고리" />

        <el-table-column label="사진">
          <template #default="scope">
            <el-image
              v-if="scope.row.imageUrl"
              :src="scope.row.imageUrl"
              style="width: 100px; height: 100px"
            ></el-image>
          </template>
        </el-table-column>

        <el-table-column prop="name" label="이름" />

        <el-table-column label="가격">
          <template #default="scope">
            $ {{ scope.row.price }}
          </template>
        </el-table-column>

        <el-table-column>
          <template #default="scope">
            <el-button @click="openItemFeedbackDialog(scope.row)">
              최근 피드백 보기
            </el-button>
            
            <el-button type="primary" @click="addItemToMenu(scope.row)">
              메뉴에 추가
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination 
        v-model:currentPage="currentPageNumber"
        v-model:page-size="currentPageSize"
        @current-change="retrieveItems" 
        @size-change="retrieveItems"
        :total="total" 
        layout="sizes, total, ->, prev, pager, next" 
      />
    </el-dialog>

    <el-dialog
      v-model="itemFeedbackDialogVisible"
      :title="`최근 피드백 - ${selectedItem?.name}`"
    >
      <el-table :data="selectedItemFeedbacks">
        <el-table-column prop="menuDate" label="날짜" />
        <el-table-column prop="rate" label="별점" />
        <el-table-column prop="content" label="내용" />
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
const { DateTime } = require('luxon')
import utils from "@/helper/utils.js"
import { ElMessage } from 'element-plus'
import ItemService from "../services/ItemService"
import MenuService from "../services/MenuService"
import ItemFeedbackService from '../services/ItemFeedbackService'

export default {
  data() {
    return {
      query: '',
      date: null,
      items: [],
      currentPageNumber: 1,
      currentPageSize: 20,
      total: 0,
      dialogVisible: false,
      menu: null,
      categories: [],
      itemFeedbackDialogVisible: false,
      selectedItem: null,
      selectedItemFeedbacks: []
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isAdmin() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    }
  },
  methods: {
    displayMenuTitle() {
      if (!this.date) {
        return ''
      }

      const dateStr = DateTime.fromISO(this.date).toFormat('M/d (EEE)')

      if (!this.menu) {
        return `No Menu - ${dateStr}`
      }

      return `Menu - ${dateStr}`
    },
    async retrieveItems() {
      try {
        const resp = await ItemService.getAll(this.query, this.currentPageNumber, this.currentPageSize)
        this.items = resp.data.rows
        this.total = resp.data.count;
        console.log("items: ", this.items);
      } catch (e) {
        utils.handleHttpError(e, this)
      }
    },
    addToCart(item) {
      this.$store.dispatch('cart/addToCart', item)
      ElMessage({
        type: 'success',
        message: 'Item added to cart successfully',
      })
    },
    async retrieveMenuByDate() {
      try {
        const response = await MenuService.findByDate(this.date)
        if (response.data.length === 1) {
          this.menu = response.data[0]
          console.log('menu: ', this.menu);

          // Categorize items
          const categories = [];
          for (const item of this.menu.items.filter(i => !!i.category)) {
            if (categories.filter(c => c.id === item.category.id).length === 0) {
              categories.push(JSON.parse(JSON.stringify(item.category)));
            }

            if (!categories.filter(c => c.id === item.category.id)[0].items) {
              categories.filter(c => c.id === item.category.id)[0].items = [];
            }
            
            categories.filter(c => c.id === item.category.id)[0].items.push(item);
          }

          categories.sort((a, b) => a.order - b.order);

          if (this.menu.items.filter(i => !i.category).length > 0) {
            categories.push({
              name: 'Uncategorized',
              items: this.menu.items.filter(i => !i.category)
            })
          }
          
          console.log('categories: ', categories);
          this.categories = categories;
        } else {
          this.menu = null
        }
      } catch (e) {
        console.log(e)
      }
    },
    async openDialog() {
      await this.retrieveItems()
      this.dialogVisible = true
    },
    async addItemToMenu(item) {
      try {
        await ItemService.addItemToMenu({
          itemId: item.id, 
          menuDate: this.date
        })
        this.$message.success('아이템이 메뉴에 추가됐습니다.')
        
        this.retrieveMenuByDate()
      } catch (e) {
        utils.handleHttpError(e, this)
      }
    },
    async removeItemFromMenu(item) {
      try {
        await ItemService.removeItemFromMenu({
          itemId: item.id, 
          menuDate: this.date
        })
        this.$message.success('아이템이 메뉴에서 제외되었습니다.')
        
        this.retrieveMenuByDate()
      } catch (e) {
        utils.handleHttpError(e, this)
      }
    },
    async openItemFeedbackDialog(item) {
      try {
        this.selectedItem = item
        const resp = await ItemFeedbackService.getMostRecentItemFeedbacks(item.id)
        this.selectedItemFeedbacks = resp.data
        console.log('selectedItemFeedbacks: ', this.selectedItemFeedbacks)
        this.itemFeedbackDialogVisible = true
      } catch (e) {
        utils.handleHttpError(e, this)
      }
    }
  },
  async mounted() {
    try {
      const resp = await MenuService.getClosestFutureMenu()
      const closestFutureMenu = resp.data
      console.log('closestFutureMenu: ', closestFutureMenu)
      this.date = closestFutureMenu.date
    } catch (e) {
      utils.handleHttpError(e, this)
      return
    }

    this.retrieveMenuByDate()
  }
}
</script>

<style scoped>
.item-card {
  margin-top: 20px;
  height: 450px;
}
</style>