import http from '@/http-common'

const BASE_API_URL = '/item'

class ItemService {
  getAll(query, pageNumber, pageSize) {
    return http.get(`${BASE_API_URL}/getAll?query=${query}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
  }

  get(id) {
    return http.get(`${BASE_API_URL}/${id}`);
  }

  create(data) {
    return http.post(`${BASE_API_URL}`, data);
  }

  update(id, data) {
    return http.put(`${BASE_API_URL}/${id}`, data);
  }

  delete(id) {
    return http.delete(`${BASE_API_URL}/${id}`);
  }

  deleteAll() {
    return http.delete(`${BASE_API_URL}`);
  }

  findByTitle(title) {
    return http.get(`${BASE_API_URL}?title=${title}`);
  }

  addItemToMenu(data) {
    return http.post(`${BASE_API_URL}/addItemToMenu`, data)
  }

  removeItemFromMenu(data) {
    return http.post(`${BASE_API_URL}/removeItemFromMenu`, data)
  }
}

export default new ItemService()
